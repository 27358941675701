import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { database } from "../Admin/Config";
import { createSearchParams, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import './DynamicRoute.css';
import noResult from '../Assets/noResult.png'
import Loading from "../Component/Loading";
import ScrollTop from "../Component/ScrollTop";

function DynamicRoute(){
    const [language,setLanguage] = useState([])
    const navigate = useNavigate();

    const getDocuments =async()=>{
        const getLanguage = collection(database,"category")
        const data = await getDocs(getLanguage)
         // setBlog(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
         setLanguage(data.docs.map((doc)=>({...doc.data(),id:doc.id})))

     }

    useEffect(()=>{
        getDocuments()
    },[])

    const handleClick =(lan)=>{
        navigate(lan)
    }

    return(
        <div>
            <Header/>
            <div className="languageContainer">
            <div className='main'>
                {language.length>0?
                language.map((val,i)=><div className="cards cardBg" onClick={()=>handleClick(val.language)}>
                    <p className="language">{val.language}</p>
                </div>)
            :
            <div>
                <img src={noResult} height="400px" width="400px" alt="no result" />
                <h1 className="loading">Loading...</h1>
                {/* <Loading /> */}
            </div>
            }
            </div>
            </div>
            <div>
                <Footer/>
            </div>
            {/* <ScrollTop/> */}
        </div>
    )
}
export default DynamicRoute;