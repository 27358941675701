import React, { useEffect, useState } from "react";
import Popup from "../Popup/Popup";
import "./Home.css";
import GSignUp from "../Admin/GsignIn";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { database } from "../Admin/Config";
// import insta from '../Assets/instagram.png'
// import linkedin from '../Assets/linkedin.png'
// import github from '../Assets/github-sign.png'
// import youtube from '../Assets/youtube.png'
import noResult from '../Assets/noResult.png'
import { createSearchParams, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Loading from "../Component/Loading";
import ScrollTop from "../Component/ScrollTop";
import { Helmet } from "react-helmet";

function HomeScreen(){
    const [state,setState] = useState([])
    const [search,setSearch] = useState([])
    const [open,setOpen] = useState(false)
    const [result,setResult] = useState(false)
    const [clickIndex,setClickIndex] = useState(0)
    // const [blog,setBlog] = useState([])
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    // // social media links
    // const instaLink = "https://www.instagram.com/coding__comics/"
    // const linkedLink = "https://www.linkedin.com/in/ajith-kumar-s-a084861b0/"
    // const gitLink = "https://github.com/AkajithAk"
    // const ytLink = "https://www.youtube.com/@codingcomics"


    
    const navigate = useNavigate();
    const getUrlLanguage = window.location.href.split('/')
    
    const getBlogPost = async()=>{
        const postRef = doc(database, 'blogPost', 'languages');
        const blogCollection = collection(postRef,getUrlLanguage[3])

        // const blogCollection = collection(database,"blog")
        const data = await getDocs(blogCollection)
        // setBlog(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
        setState(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
        setSearch(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
    }
    useEffect(()=>{
        getBlogPost()
        ReactGA.pageview(window.location.pathname)
    },[])

    const handleChange=(e)=>{
        e.preventDefault();
        var upCaseData=e.target.value
        var searchedVal =   search.filter(name => name.title.includes(upCaseData.toLowerCase())) // real filter in local js code
            setState(searchedVal)

        if(e.target.value.length == 0){
            getBlogPost()
            setState(state)
        }
        if(searchedVal.length == 0){
            setResult(true)
        }else{
            setResult(false)
        }
    }
    const urlSplit = window.location.href.split('/')
    console.log("window.location",urlSplit)
    const handleDelete =async(id)=>{
        console.log(id)
        // const postRef = doc(database, 'blogPost', 'languages'); 
        // const blogCollection = collection(postRef,id)
        const blogCollection = doc(database, 'blogPost', 'languages', urlSplit[3], id)
        console.log(blogCollection)
        await deleteDoc(blogCollection)
        alert("Successfully Deleted...")
    }
    const handleOpen = (index,title) =>{
        setOpen(!open)
        setClickIndex(index)
        // navigate("/page/2");
        // navigate(`/${state[index].title}`,{state:state[index]});
        navigate({
            pathname:"page",
            search:createSearchParams({
                id:title,
            }).toString()
        })
    }
    // const handleOpenClose = (val) =>{
    //     setOpen(val)
    // }
    // const handleTop = () =>{
    //     window.scroll({
    //         top:0,
    //         behavior:'smooth'
    //     })
    // }

    const handleEdit =(id,details)=>{
        console.log(id,"details")
        navigate('/comics?type=edit',{state:{id:id,data:details}});
        // navigate({
        //     pathname:"comics",
        //     search:createSearchParams({
        //         id:details,
        //     }).toString()
        // })
    }

    return(
        <div>
            {/* {open?<Popup data={state[clickIndex]} close={handleOpen}/>: */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>{getUrlLanguage[3]}</title>
                <meta name="description" content={getUrlLanguage[3]} />
                <meta name="og:title" property="og:title" content={getUrlLanguage[3]}/>
                <meta name="og:description" property="og:title" content={getUrlLanguage[3]}/>
            </Helmet>
            <div style={{margin:'0',padding:'0'}}>
                {/* Header */}
                {/* <div className="logoSignup">
                    <div className="logo">
                        Coding <span className="second_logo">Comics</span>
                    </div>
                    <div className="gSignup">
                        <GSignUp/>
                    </div>
                </div> */}
                <Header/>
                {/* Cards */}
                <div className="containerMain">
                    <div className="search_container">
                        <div>
                            <input name="search" onChange={(e)=>handleChange(e)} className="search" placeholder="Type to Search" autoComplete="off" />
                            <button className="search_button">Search</button>
                        </div>
                    </div>
                    <div className="main center_div">
                        {state.length>0?
                        state.map((val,i)=>
                        <div className="cards" key={i}>
                            { localStorage.getItem("email") === 'akajith919@gmail.com' && localStorage.getItem('solution') === 'codingAjithComics' ?
                                <div className="postDelete" onClick={()=>handleDelete(val.id)}>X</div>:''
                            }
                            { localStorage.getItem("email") === 'akajith919@gmail.com' && localStorage.getItem('solution') === 'codingAjithComics' ?
                                <div className="postEdit" onClick={()=>handleEdit(val.id,val)}>E</div>:''
                            }
                            <div onClick={()=>{handleOpen(i,val.title)}}>
                                <div className="first_text">
                                    <div className="text_center">
                                        {val.title[0]}
                                    </div>
                                </div>
                                <div className="cardTitleContainer">
                                    <h1 className="card_title" title={val.title}>{val.title}</h1>
                                </div>
                            </div>
                        </div>
                        ):
                        <div>
                            <img src={noResult} height="400px" width="400px" alt="no result" />
                            <h1 className="loading">{result ? "No Result" : "Loading..."}</h1>
                            {/* <Loading /> */}
                        </div>
                        }
                    </div>
                </div>
                {/* Footer */}
                <div>
                    <Footer/>
                </div>
                {/* <div className="footer">
                    <div>
                        <h1> Coding Comics</h1>
                        <p>This is a coding comics blog site, where you can discover excellent<br/> content related to React.js, JavaScript, HTML, CSS, bug fixes, and other tech-related topics.</p><br/>
                    </div>
                    <div className="socialRow">
                        <div>
                            <a href={instaLink} target="_blank"><img src={insta} atr='instagram' className="socialLogo" /> </a>
                        </div>
                        <div>
                            <a href={linkedLink} target="_blank"><img src={linkedin} atr='instagram' className="socialLogo" /> </a>
                        </div>
                        <div>
                            <a href={gitLink} target="_blank"><img src={github} atr='instagram' className="socialLogo" /> </a>
                        </div>
                        <div>
                            <a href={ytLink} target="_blank"><img src={youtube} atr='instagram' className="socialLogoYt" /> </a>
                        </div>
                    </div>
                    <div className="copyRight">
                        <hr width='70%' />
                        <h3 className="copyRightText">CopyRight &copy; {year} Coding Comics</h3>
                    </div>
                </div> */}
                {/* <div className="scrollTop">
                    <button className="scrollTopBtn" onClick={handleTop}>Top</button>
                </div> */}
                {/* <ScrollTop /> */}
            </div>
          {/* } */}
        </div>
    );
}
export default HomeScreen;