import { useState } from 'react';
import './App.css';
import ReactGA from 'react-ga';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DynamicRoute from './DynamicRoute/DynamicRoute';
import HomeScreen from './Home/Home';
import Popup from './Popup/Popup';
import Admin from './Admin/Admin';
ReactGA.initialize('G-35CCSMPYSX');

function App() {
  const [open,setOpen] = useState(false)
    
    const handleOpen = (index) =>{
        setOpen(!open)
    }

  return (
    <div>
      <BrowserRouter>
            <Routes>
                <Route path="/" element={<DynamicRoute/>} />
                <Route path="/:language" element={<HomeScreen/>} />
                <Route path="/:language/:id" element={<Popup close={handleOpen}/>} />
                {(localStorage.getItem('email') === 'akajith919@gmail.com' && localStorage.getItem('solution') === 'codingAjithComics')?
                    <Route path="/comics" element={<Admin/>} />
                :''
                }
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
