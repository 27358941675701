import React, { memo, useEffect, useState } from "react";
import './Popup.css';
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { database } from "../Admin/Config";
import { addDoc, collection, doc, getDocs, query, where } from "firebase/firestore";
import ship from '../Assets/ship.png';
import bottle from '../Assets/nature.png';
import insta from '../Assets/instagram.png'
import linkedin from '../Assets/linkedin.png'
import github from '../Assets/github-sign.png'
import youtube from '../Assets/youtube.png'
import burger from '../Assets/menu.png'
import Header from "../Header/Header";
import ScrollTop from "../Component/ScrollTop";
import { Helmet } from "react-helmet";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import AdSense from 'react-adsense';

function Popup(props){
    const [menu,setMenu] = useState(false)
    const [isDesk,setIsDesk] = useState(false)
    const [state,setState] = useState([])
    // social media links
    const instaLink = "https://www.instagram.com/coding__comics/"
    const linkedLink = "https://www.linkedin.com/in/ajith-kumar-s-a084861b0/"
    const gitLink = "https://github.com/AkajithAk"
    const ytLink = "https://www.youtube.com/@codingcomics"

    const valueDb = collection(database,"feedback")

    const [data,setData] = useState({
        desc:"",
        gLink:"",
        ifameCode:"",
        listData:"[]",
        title:"",
        ytLink: "",
        suggestion:[]})
    const navigate = useNavigate(); // route navigation

    const [searchParams] = useSearchParams()

    let getUrlLanguage = window.location.href.split('/');    

    const getUrlData=async(url)=>{
        getUrlLanguage = window.location.href.split('/')    
        const postRef = doc(database, 'blogPost', 'languages');
        const citiesRef = collection(postRef,getUrlLanguage[3])
        const q = query(citiesRef, where("title", "==", url !=='' ? url : searchParams.get('id')));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const existingOgTitle = document.querySelector('meta[property="og:title"]');
            const existingOgDesc = document.querySelector('meta[property="og:description"]');
            if (existingOgTitle) {
              existingOgTitle.remove();
              existingOgDesc.remove();
            }
            setData(doc.data());
        });
    }

    if(data.title==''){
        getUrlData('');
    }

    const iframeUrl =data.ifameCode ?data.ifameCode:null;

    const handleSplit =(val)=>{
       const splitVal = val.split(':')

       let finalVal;
       let allow = val.includes(':')

       if(allow){
           let zero = <u style={{ fontWeight: '900'}}>{splitVal[0]}</u>;
           let one = <span>{splitVal[1]}</span>;
           finalVal= [zero,":",one ] ;
       }else{
        finalVal= val;
       }
       return finalVal;
    }

    const handleClose =()=>{
        navigate(`/${getUrlLanguage[3]}`);
    }
    
    const handleFeedback =async(e)=>{
        e.preventDefault()
        if((localStorage.getItem('email') || e.target.email.value) && e.target.feedback.value){
            await addDoc(valueDb,{
                email:localStorage.getItem('email')?localStorage.getItem('email'):e.target.email.value,
                feedback:e.target.feedback.value,
                isRead:false,
                created: new Date()
            })
            alert('Thank you for your suggestion...')
            e.target.email.value = ''
            e.target.feedback.value = ''
        }
    }
    const handleNavigate = (path)=>{
        navigate(`/${path}`)
    }
    const getBlogPost = async()=>{
        const postRef = doc(database, 'blogPost', 'languages');
        const getUrlLanguage = window.location.href.split('/')
        console.log(getUrlLanguage,"getUrlLanguage")
        const blogCollection = collection(postRef,getUrlLanguage[3])
        const data = await getDocs(blogCollection)
        setState(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
    }
    
    useEffect(()=>{
        getBlogPost()
    },[])

    const handleChangeUrl =(title)=>{
        navigate({
            search:createSearchParams({
                id:title,
            }).toString()
        })
        getUrlData(title);
    }

    const handleMenu =(val) =>{
        console.log(val,"valdaadtad")
        setMenu(val)
    }
    useEffect(()=>{
       if( window.innerWidth < 826 ){
            setIsDesk(true)
       }else{
            setIsDesk(false)
        }
    })
    return(
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{data.title}</title>
                <meta name="description" content={data.desc} />
                <meta name="og:title" property="og:title" content={data.title}/>
                <meta name="og:description" property="og:description" content={data.desc}/>
            </Helmet>
            <Header close={menu} hamburger={handleMenu} />
            <div className={menu?"popup overflow":"popup"}>
            <meta property="og:title" content={data.title} />
                {/* <div onClick={()=>setMenu(!menu)} className="burgerIcon mobile">
                   <img src={burger} height='42px' alt="hamburgerMenu" />
                </div> */}
                {/* {
                    menu?
                    <div className="popupSideMenu">
                        <div className="menuSpacing">
                            <h2 className="sideTitle">{getUrlLanguage[3]}</h2>
                            {state.map((val,i)=>
                            <div className={data.title == val.title ? 'activeMenu':'nonActiveMenu'} onClick={()=>handleChangeUrl(val.title)}>
                                <h4 className="popupElipis">{val.title}</h4>
                            </div>
                            )}
                        </div>
                    </div>
                    :""
                } */}
                {/* <div onClick={handleClose} className="popupCloseIcon">X</div> */}
                <div className="mainContainer">
                    {menu || !isDesk?
                    <div className="firstContainer">
                        <div className="menuCloseIcon" onClick={()=>setMenu(false)}>X</div>
                        {/* <div className="popupSideMenu isMoile"> */}
                            <div className="menuSpacing">
                                <h2 className="sideTitle">{getUrlLanguage[3]}</h2>
                                {state.map((val,i)=>
                                    <div className={data.title == val.title ? 'activeMenu':'nonActiveMenu'} onClick={()=>handleChangeUrl(val.title)}>
                                        {/* <h4 className="popupElipis">{i+1}.  {val.title}</h4> */}
                                        <h4 className="popupElipis">{val.title}</h4>
                                    </div>
                                )}
                            </div>
                        {/* </div> */}
                    </div>
                    :''
                    } 
                    <div className="secondContainer">
                        <div className="content">
                            <h1 className="popupTitle">{data.title}</h1>
                            <p className="decription">{data.desc}</p>
                            {iframeUrl === null?'':
                                <div className="iframe">
                                    <iframe className="iframeTag" height="315" src={iframeUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen={true}></iframe>
                                </div>
                            }
                            <div>
                                {data.gLink !=='' || data.ytLink !=='' ?
                                <div className="codeContainer">
                                { data.title!== ''?<h2>Reference</h2>:'' }
                                    <div className="refRow">
                                        {data.gLink?
                                        <h4>
                                            <a href={data.gLink} target="_blank" rel="This is the link to my GitHub page, where I can add code related to the post">
                                            <div className="shaking">
                                                <img src={github} atr='instagram' className="socialLogo" alt="git" />
                                                <span>Source code</span>
                                            </div>
                                            </a>
                                        </h4>:''}
                                        {data.ytLink?
                                        <h4>
                                            <a href={data.ytLink} target="_blank" rel="This is the link to my YouTube channel where I upload coding videos related to React.js, JavaScript, HTML, CSS, and Node.js, complementing the content on the Coding Comics blog.">
                                                <div className="shaking">
                                                    <img src={youtube} atr='instagram' className="socialLogo" />
                                                    <span>Youtube video</span>
                                                </div>
                                            </a>
                                        </h4>:''}
                                    </div>
                                </div>:''}
                            </div>
                            <div>
                            {
                                JSON.parse(data.listData).map((pic,i)=>
                                    <div key={i} className={pic.multiImgs!==''?"loop":''}>
                                        <p>{handleSplit(pic.pTitle)}</p>
                                        {pic.multiCode?
                                            <SyntaxHighlighter language="javascript">
                                                {pic.multiCode}
                                            </SyntaxHighlighter>:''
                                        }
                                        {
                                            pic?.imgtype?.includes('pdf') == true?
                                            <div className="fileBox">
                                                <a className="fileDownloader" href={pic.multiImgs} target="_blank" download>Download Pdf</a>
                                            </div>:(pic.multiImgs!==''?
                                            <img className="imgHeight" src={pic.multiImgs} alt={pic.pTitle} />:''
                                            )
                                        }
                                    </div>
                                )
                            }
                            </div>
                        </div>
                        <div className="markettingSection">
                            <h1 className="attrTitle">What aspects of technology do you want to learn about?</h1>
                            <form onSubmit={(e)=>handleFeedback(e)}>
                                {
                                    localStorage.getItem('email')?"":
                                <input className="email" name="email" placeholder="Email" />}
                                <textarea className="feedback" name="feedback" placeholder="Topics" /> 
                                <button className="feedBtn">Send</button>
                            </form>
                            <br/>
                            <hr/>
                            { data?<h2 className="resources">Resources</h2>:'' }
                            <div className="techList">
                                <div onClick={()=>handleNavigate(data.suggestion?data.suggestion[0]:'react.js')} className="techCard">
                                    <h2>{data.suggestion?data.suggestion[0]:'React.js'}</h2>
                                    <img src={ship} height='150px' width="150px" />
                                </div>
                                <div  onClick={()=>handleNavigate(data.suggestion?data.suggestion[1]:'javascript')} className="techCard">
                                    <h2>{data.suggestion?data.suggestion[1]:'Javascript'}</h2>
                                    <img src={bottle} height='150px' width="150px" />
                                </div>
                            </div>
                            <br/>
                            <hr/>
                            <h2>Follow Me</h2>
                            <div className="socialRow">
                                <div>
                                    <a href={instaLink} target="_blank"><img src={insta} atr='instagram' className="socialLogo" /> </a>
                                </div>
                                <div>
                                    <a href={linkedLink} target="_blank"><img src={linkedin} atr='instagram' className="socialLogo" /> </a>
                                </div>
                                <div>
                                    <a href={gitLink} target="_blank"><img src={github} atr='instagram' className="socialLogo" /> </a>
                                </div>
                                <div>
                                    <a href={ytLink} target="_blank"><img src={youtube} atr='instagram' className="socialLogoYt" /> </a>
                                </div>
                            </div>
                            <AdSense.Google
                                client='ca-pub-5911408104517788'
                                slot='4031403839'
                                format='auto' // ad format, e.g., 'auto', '300x250'
                            />
                        </div>
                    </div>
                </div>
                {/* <ScrollTop/> */}
            </div>
        </div>
    )
}
export default memo(Popup);